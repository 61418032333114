import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from "./router";
import echarts from "echarts";
Vue.prototype.$echarts = echarts;

// 判端用户中心按钮权限
import {hasBtnPermission} from '@/utils/permission';
Vue.prototype.$hasBtnPermission = hasBtnPermission;

import preventReClick from './utils/preventRepeatClick';
Vue.use(preventReClick)

import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

// swiper切换
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper);

import './assets/theme/index.css'
Vue.config.productionTip = false
Vue.use(ElementUI);
import { message } from '@/utils/resetMessage.js'
Vue.prototype.$message = message
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
