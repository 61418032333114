export function hasBtnPermission(id) {
    let btnPermission = JSON.parse(sessionStorage.getItem('btnPermission'))||[];
    if (id) {
        let ids = btnPermission.map(i => i.id);
        let index = btnPermission.findIndex(i => i.id == id);
        if (ids.includes(id)) {
            return { isShow: true, name: btnPermission[index].resourceName };
        } else {
            return { isShow: false, name: '无权限' }
        }
    } else return { isShow: false, name: '无权限' }
};
