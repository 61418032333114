/*
 * @Author: Haotian Zheng
 * @Date: 2023-07-13 19:17:17
 * @Descripttion:
 * @LastEditors: cuicuizi
 * @LastEditTime: 2024-05-21 17:55:07
 */
import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('../pages/login/index.vue'),
      meta: {
        title: '登录',
        keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
        description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
        isAute: false,
      },
      children: [
        {
          path: '/login',
          name: 'Login',
          component: () => import('../pages/login/login/index.vue'),
          meta: {
            title: '登录',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
        },
        {
          path: '/register',
          name: 'Register',
          component: () => import('../pages/login/register/index.vue'),
          meta: {
            title: '注册',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
        },
        {
          path: '/forget',
          name: 'Forget',
          component: () => import('../pages/login/forget/index.vue'),
          meta: {
            title: '忘记密码',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
        },
        {
          path: '/agreement',
          name: 'Agreement',
          component: () => import('../pages/login/agreement.vue'),
          meta: {
            title: '用户协议',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
        },
        {
          path: '/conceal',
          name: 'Conceal',
          component: () => import('../pages/login/conceal.vue'),
          meta: {
            title: '隐私权政策',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
        },
      ],
    },
    {
      path: '/staging',
      name: 'Staging',
      component: () => import('../pages/staging/index.vue'),
      meta: {
        title: '工作台',
        keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
        description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
        isAute: false,
      },
      children: [
        {
          path: '/staging',
          name: 'Staging',
          component: () => import('../pages/staging/index/index.vue'),
          meta: {
            title: '工作台',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
        },
        {
          path: '/userDelete',
          name: 'UserDelete',
          component: () => import('../pages/staging/index/userDelete.vue'),
          meta: {
            title: '员工被删除',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
        },
        {
          path: '/financeBoard',
          name: 'financeBoard',
          component: () => import('../pages/financeBoard/index.vue'),
          meta: {
            title: '智能财务管家',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: true,
          },
        },
        {
          path: '/xiaofuBoard',
          name: 'xiaofuBoard',
          component: () => import('../pages/xiaofuBoard/index.vue'),
          meta: {
            title: '小福智融',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: true,
          },
        },
        {
          path: '/taxBoard',
          name: 'taxBoard',
          component: () => import('../pages/taxBoard/index.vue'),
          meta: {
            title: '智能云代账',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: true,
          },
        },
        {
          path: '/agent',
          name: 'Agent',
          component: () => import('../pages/agent/index.vue'),
          meta: {
            title: '代理商管理',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: true,
          },
          children: [
            {
              path: '/',
              name: 'Customer',
              component: () => import('../pages/agent/customer/index.vue'),
              meta: {
                title: '客户列表',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: true,
                hasMenu: true,
                breadcrumb: {
                  name: '客户列表',
                  path: '/agent',
                  isJump: false,
                },
              },
            },
            {
              path: '/agent/agent_order',
              name: 'agent_order',
              component: () => import('../pages/agent/agent_order/index.vue'),
              meta: {
                title: '代客下单记录',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: true,
                hasMenu: true,
                breadcrumb: {
                  name: '代客下单记录',
                  path: '/agent/agent_order',
                  isJump: false,
                },
              },
            },
            {
              path: '/agent/agent_order/agent_order_details',
              name: 'agent_order_details',
              component: () => import('../pages/agent/agent_order/details.vue'),
              meta: {
                title: '我的订单',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: true,
                hasMenu: true,
                breadcrumb: {
                  name: '代客下单记录',
                  path: '/agent/agent_order',
                  isJump: true,
                },
              },
            },
            {
              path: '/agent/order_notes',
              name: 'order_notes',
              component: () => import('../pages/agent/order_notes/index.vue'),
              meta: {
                title: '客户订单记录',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: true,
                hasMenu: true,
                breadcrumb: {
                  name: '客户订单记录',
                  path: '/agent/order_notes',
                  isJump: false,
                },
              },
            },
            {
              path: '/agent/BillEcharts',
              name: 'BillEcharts',
              component: () => import('../pages/agent/billEcharts/index.vue'),
              meta: {
                title: '账单统计',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: true,
                hasMenu: true,
                breadcrumb: {
                  name: '账单统计',
                  path: '/agent/BillEcharts',
                  isJump: false,
                },
              },
            },
            {
              path: '/agent/settle_management',
              name: 'settleManagement',
              component: () => import('../pages/agent/settle_management/index.vue'),
              meta: {
                title: '账单记录',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: true,
                hasMenu: true,
                breadcrumb: {
                  name: '账单记录',
                  path: '/agent/settle_management',
                  isJump: false,
                },
              },
            },
            {
              path: '/agent/settle_management/settle_accounts',
              name: 'settleManagement',
              component: () => import('../pages/agent/settle_management/components/settleAccounts.vue'),
              meta: {
                title: '发起结算',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: true,
                hasMenu: true,
              },
            },
            {
              path: '/agent/settle_management/bill_details',
              name: 'settleManagement',
              component: () => import('../pages/agent/settle_management/components/billDetails.vue'),
              meta: {
                title: '账单详情',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: true,
                hasMenu: true,
                breadcrumb: {
                  name: '账单记录',
                  path: '/agent/settle_management',
                  isJump: true,
                },
              },
            },
            {
              path: '/agent/settement_record',
              name: 'settementRecord',
              component: () => import('../pages/agent/settementRecord/index.vue'),
              meta: {
                title: '结算记录',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: true,
                hasMenu: true,
                breadcrumb: {
                  name: '结算记录',
                  path: '/agent/settement_record',
                  isJump: false,
                },
              },
            },
            {
              path: '/agent/application_open',
              name: 'applicationOpen',
              component: () => import('../pages/agent/application_open/index.vue'),
              meta: {
                title: '企业产品开放',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: true,
                hasMenu: true,
                breadcrumb: {
                  name: '企业产品开放',
                  path: '/agent/application_open',
                  isJump: false,
                },
              },
            },
          ],
        },
        {
          path: '/messageCenter',
          name: 'MessageCenter',
          component: () => import('../pages/messageCenter/index'),
          meta: {
            title: '消息中心',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
          children: [
            {
              path: '/',
              name: 'MessageCenter',
              component: () => import('../pages/messageCenter/list/index.vue'),
              meta: {
                title: '消息中心',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
              },
            },
            {
              path: '/messageCenter/noticePage',
              name: 'noticePage',
              component: () => import('../pages/messageCenter/list/noticePage.vue'),
              meta: {
                title: '消息中心公告',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
              },
            },
            {
              path: '/messageCenter/systemPage',
              name: 'systemPage',
              component: () => import('../pages/messageCenter/list/systemPage.vue'),
              meta: {
                title: '消息中心系统消息',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
              },
            },
            {
              path: '/messageCenter/details',
              name: 'MessageDetails',
              component: () => import('../pages/messageCenter/details/index.vue'),
              meta: {
                title: '消息中心详情',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
              },
            },
          ],
        },
        {
          path: '/companyDisable',
          name: 'CompanyDisable',
          component: () => import('../pages/staging/index/companyDisable.vue'),
          meta: {
            title: '企业被禁用',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
        },
        {
          path: '/improveData',
          name: 'ImproveData',
          component: () => import('../pages/company/improveData.vue'),
          meta: {
            title: '完善资料',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
        },
        {
          path: '/addCompany',
          name: 'AddCompany',
          component: () => import('../pages/company/addCompany.vue'),
          meta: {
            title: '创建企业',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
            isAddCompany:true
            
          },
        },
        {
          path: '/userSetting',
          name: 'userSetting',
          component: () => import('../pages/userSetting/index.vue'),
          meta: {
            title: '个人设置',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
          children: [
            {
              path: '/',
              name: 'userSetting',
              component: () => import('../pages/userSetting/userSetting/index.vue'),
              meta: {
                title: '个人信息',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
                hasMenu: true,
              },
            },
            {
              path: '/userSetting/userSafe',
              name: 'userSafe',
              component: () => import('../pages/userSetting/userSafe/index.vue'),
              meta: {
                title: '账号安全',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
                hasMenu: true,
              },
            },
            {
              path: '/userSetting/settle_account',
              name: 'settleAccount',
              component: () => import('../pages/userSetting/settleAccount/index.vue'),
              meta: {
                title: '结算账户',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: true,
                hasMenu: true,
              },
            },
            {
              path: '/userSetting/my_businesses',
              name: 'myBusinesses',
              component: () => import('../pages/userSetting/myBusinesses/index.vue'),
              meta: {
                title: '我的企业',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: true,
                hasMenu: true,
              },
            },
          ],
        },
        {
          path: '/userCenter',
          name: 'UserCenter',
          component: () => import('../pages/userCenter/index.vue'),
          meta: {
            title: '用户中心',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
          children: [
            {
              path: '/',
              name: 'myApp',
              component: () => import('../pages/userCenter/myApp/index.vue'),
              meta: {
                title: '已购产品',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
                hasMenu: true,
                breadcrumb: {
                  name: '已购产品',
                  path: '/userCenter',
                  isJump: false,
                },
              },
            },
            {
              path: '/userCenter/payment',
              name: 'Payment',
              component: () => import('../pages/payment/index.vue'),
              meta: {
                title: '我的订单',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
                hasMenu: false,
                breadcrumb: {
                  name: '我的订单',
                  path: '/userCenter/order',
                  isJump: true,
                },
              },
            },
            {
              path: '/userCenter/order',
              name: 'Order',
              component: () => import('../pages/userCenter/order/index.vue'),
              meta: {
                title: '我的订单',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
                hasMenu: true,
                breadcrumb: {
                  name: '我的订单',
                  path: '/userCenter/order',
                  isJump: false,
                },
              },
            },
            {
              path: '/userCenter/order/details/:id',
              name: 'OrderDetails',
              component: () => import('../pages/userCenter/order/details.vue'),
              meta: {
                title: '订单详情',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
                hasMenu: false,
                breadcrumb: {
                  name: '我的订单',
                  path: '/userCenter/order',
                  isJump: true,
                },
              },
            },
            {
              path: '/userCenter/invoicingRecord',
              name: 'InvoicingRecord',
              component: () => import('../pages/userCenter/invoicingRecord/index.vue'),
              meta: {
                title: '开票记录',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
                hasMenu: true,
                breadcrumb: {
                  name: '开票记录',
                  path: '/userCenter/invoicingRecord',
                  isJump: false,
                },
              },
            },
            {
              path: '/userCenter/invoicingRecord/details',
              name: 'InvoicingRecordDetails',
              component: () => import('../pages/userCenter/invoicingRecord/details.vue'),
              meta: {
                title: '开票记录详情',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
                hasMenu: false,
                breadcrumb: {
                  name: '开票记录',
                  path: '/userCenter/invoicingRecord',
                  isJump: true,
                },
              },
            },
            {
              path: '/userCenter/roles',
              name: 'roles',
              component: () => import('../pages/userCenter/Role/RoleList.vue'),
              meta: {
                title: '角色管理',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
                hasMenu: true,
                breadcrumb: {
                  name: '角色管理',
                  path: '/userCenter/roles',
                  isJump: false,
                },
              },
            },
            {
              path: '/userCenter/userList',
              name: 'userList',
              component: () => import('../pages/userCenter/UserOrganization/UserList.vue'),
              meta: {
                title: '组织与用户',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
                hasMenu: true,
                breadcrumb: {
                  name: '组织与用户',
                  path: '/userCenter/userList',
                  isJump: false,
                },
              },
            },
            {
              path: '/userCenter/invitationRecord',
              name: 'InvitationRecord',
              component: () => import('../pages/userCenter/invitationRecord/index.vue'),
              meta: {
                title: '邀请记录',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
                hasMenu: true,
                breadcrumb: {
                  name: '邀请记录',
                  path: '/userCenter/invitationRecord',
                  isJump: false,
                },
              },
            },
            {
              path: '/userCenter/applyInvoice',
              name: 'ApplyInvoice',
              component: () => import('../pages/userCenter/applyInvoice/index.vue'),
              meta: {
                title: '申请发票',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
                hasMenu: false,
                breadcrumb: {
                  name: '我的订单',
                  path: '/userCenter/order',
                  isJump: true,
                },
              },
            },
            {
              path: '/userCenter/dataExchange',
              name: 'dataExchange',
              component: () => import('../pages/userCenter/DataExchange/DataExchangeList.vue'),
              meta: {
                title: '数据互通',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
                hasMenu: true,
                breadcrumb: {
                  name: '数据互通',
                  path: '/userCenter/dataExchange',
                  isJump: false,
                },
              },
            },
          ],
        },
      ],
    },
    {
      path: '/problem',
      name: 'Problem',
      component: () => import('../pages/problem/index.vue'),
      meta: {
        title: '意见反馈',
        keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
        description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
        isAute: false,
      },
      children: [
        {
          path: '/problem/feedBack',
          name: 'FeedBack',
          component: () => import('../pages/problem/feedBack/index.vue'),
          meta: {
            title: '意见反馈',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
        },
        {
          path: '/problem/details',
          name: 'Details',
          component: () => import('../pages/problem/details/index.vue'),
          meta: {
            title: '意见反馈详情页',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
        },
      ],
    },
    {
      path: '/helpCenter',
      name: 'HelpCenter',
      component: () => import('../pages/helpCenter/index.vue'),
      meta: {
        title: '帮助中心',
        keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
        description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
        isAute: false,
        hasMenu: true,
      },
      children: [
        {
          path: '/helpCenter/started/:id',
          name: 'Started',
          component: () => import('../pages/helpCenter/started/index.vue'),
          meta: {
            title: '新手入门',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
            hasMenu: true,
          },
        },
        {
          path: '/helpCenter/details/:id',
          name: 'HelpCenterDetails',
          component: () => import('../pages/helpCenter/details/index.vue'),
          meta: {
            title: '详情',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
            hasMenu: true,
          },
        },
        {
          path: '/helpCenter/question/:id',
          name: 'Question',
          component: () => import('../pages/helpCenter/question/index.vue'),
          meta: {
            title: '常见问题',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
            hasMenu: true,
          },
        },
        {
          path: '/helpCenter/video/:id',
          name: 'Video',
          component: () => import('../pages/helpCenter/video/index.vue'),
          meta: {
            title: '视频教程',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
            hasMenu: true,
          },
        },
        {
          path: '/helpCenter/searchList/:wd',
          name: 'SearchList',
          component: () => import('../pages/helpCenter/searchList/index.vue'),
          meta: {
            title: '搜索结果',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
            hasMenu: false,
          },
        },
      ],
    },
    {
      path: '/',
      name: 'Index',
      component: () => import('../pages/index/index.vue'),
      meta: {
        title: '首页',
        keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
        description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
        isAute: false,
      },
      children: [
        {
          path: '/',
          name: 'Home',
          component: () => import('../pages/home/index.vue'),
          meta: {
            title: '首页',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
        },
        {
          path: '/userDisable',
          name: 'UserDisable',
          component: () => import('../pages/staging/index/userDisable.vue'),
          meta: {
            title: '员工被禁用',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
            isTop:true
          },
        },
        {
          path: '/college',
          name: 'college',
          component: () => import('../pages/college/index.vue'),
          meta: {
            title: '企服学院',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
        },
        {
          path: '/shoppingMall',
          name: 'shoppingMall',
          component: () => import('../pages/shoppingMall/index.vue'),
          meta: {
            title: '企服商城',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
        },
        {
          path: '/openPlant',
          name: 'openPlant',
          component: () => import('../pages/openPlant/index.vue'),
          meta: {
            title: '开放平台',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
        },
        {
          path: '/product',
          name: 'Product',
          component: () => import('../pages/product/index.vue'),
          meta: {
            title: '产品中心',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
          children: [
            {
              path: '/product/orderSubmit',
              name: 'OrderSubmit',
              component: () => import('../pages/userCenter/orderSubmit/index.vue'),
              meta: {
                title: '提交订单',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
                hasMenu: false,
              },
            },
            {
              path: '/product',
              name: 'Product',
              component: () => import('../pages/product/product_home/index.vue'),
              meta: {
                title: '产品与服务',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
              },
            },
            {
              path: '/product/detail/:id',
              name: 'ProductDetail',
              component: () => import('../pages/product/product_detail/index.vue'),
              meta: {
                title: '产品详情',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
              },
            },
            {
              path: '/product/sort',
              name: 'ProductSort',
              component: () => import('../pages/product/product_sort/index.vue'),
              meta: {
                title: '产品与服务分类',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
              },
            },
          ],
        },
        {
          path: '/news',
          name: 'News',
          component: () => import('../pages/news/index.vue'),
          meta: {
            title: '新闻动态',
            keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
            description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
            isAute: false,
          },
          children: [
            {
              path: '/news',
              name: 'News',
              component: () => import('../pages/news/news_list/index.vue'),
              meta: {
                title: '新闻动态',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
              },
            },
            {
              path: '/news/body',
              name: 'News',
              component: () => import('../pages/news/news_body/index.vue'),
              meta: {
                title: '新闻详情',
                keywords: ' 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务',
                description: '财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性',
                isAute: false,
              },
            },
          ],
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  const token = window.localStorage.getItem('token')
  if (to.matched.length === 0) {
    next({
      path: '/',
    })
  }
  if (to.meta.isAute) {
    if (token) {
      next()
    } else {
      next({
        path: '/login',
      })
    }
  } else {
    next()
  }
})
export default router
