<!--
 * @Author: Haotian Zheng
 * @Date: 2023-07-13 19:17:14
 * @Descripttion: 
 * @LastEditors: Haotian Zheng
 * @LastEditTime: 2023-08-04 11:25:25
-->
<template>
  <div id="app">
    <router-view class="app-in-box" />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
@import url("../src/assets/css/reset.scss");

@import url('@riophae/vue-treeselect/dist/vue-treeselect.css');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
</style>
